import React, { Ref } from "react";

import "./air-section.scss";

interface Props {
  children: any;
  className?: string;
  color?: string;
  backgroundColor?: string;
  [key: string]: any;
}

function SectionDefault(props: Props, ref: Ref<HTMLElement>) {
  const baseStyle = { backgroundColor: props.backgroundColor, color: props.color };
  const style = props.style ? Object.assign({}, baseStyle, props.style) : Object.assign({}, baseStyle);
  return (
    <section
      ref={ref}
      style={style}
      className={props.className + " air-section-wrapper margin"}
    >
      {props.children}
    </section>
  );
}
function SectionWithBackgroundColor(props: Props, ref: Ref<HTMLElement>) {
  function filteredProps() {
    const filteredProps = { ...props };
    delete filteredProps.className;
    delete filteredProps.children;
    delete filteredProps.color;
    delete filteredProps.backgroundColor;
    delete filteredProps.backgroundColor;
    return filteredProps;
  }
  const baseStyle = { backgroundColor: props.backgroundColor, color: props.color };
  const style = props.style ? Object.assign({}, baseStyle, props.style) : Object.assign({}, baseStyle);
  return (
    <section
      ref={ref}
      className="air-section-wrapper padding"
      style={style}
    >
      <div className={props.className}>{props.children}</div>
    </section>
  );
}

const Section = React.forwardRef((props: Props, ref: Ref<HTMLElement>) => {
  return props.backgroundColor
    ? SectionWithBackgroundColor(props, ref)
    : SectionDefault(props, ref);
});
Section.defaultProps = {
  className: "",
};

export default Section;
